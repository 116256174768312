import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import TrackVisibility from "react-on-screen";
import Layout from "../components/Layout";
import Progress from "../components/Progress";
import Resume from "../components/Resume";
import Sectiontitle from "../components/Sectiontitle";
import Smalltitle from "../components/Smalltitle";

function Resumes() {
  const [skills, setSkills] = useState([]);
  const [otherSkills, setOtherSkills] = useState([]);
  const [workingExperience, setWorkingExperience] = useState([]);
  const [educationExperience, setEducationExperience] = useState([]);

  useEffect(() => {
    axios.get("/api/skills").then((response) => {
      setSkills(response.data);
    });
    axios.get("/api/otherSkills").then((response) => {
      setOtherSkills(response.data);
    });
    axios.get("/api/experience").then((response) => {
      setWorkingExperience(response.data.workingExperience);
      setEducationExperience(response.data.educationExperience);
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>José Lima - Senior Software Engineer</title>
        <meta
          name="description"
          content="Experienced Senior Software Engineer with 8 years in web application development, adept at
          crafting impactful financial modules and migrating outdated technologies to modern solutions."
        />
      </Helmet>
      <div className="mi-skills-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="My Skills" />
          <div className="mi-skills">
            <div className="row mt-30-reverse">
              {skills.map((skill) => (
                <TrackVisibility
                  once
                  className="col-lg-6 mt-30"
                  key={skill.title}
                >
                  <Progress
                    title={skill.title}
                    percentage={skill.value}
                    duration={skill.duration}
                  />
                </TrackVisibility>
              ))}
            </div>
          </div>
          <div className="mi-skills mt-30">
            <Smalltitle title="Other skills" icon="construction-hammer" />
            <div className="row">
              <ul className="mi-tech-list">
                {otherSkills.map((otherSkill) => (
                  <li key={otherSkill.title} data-tip={otherSkill.title}>
                    <img src={otherSkill.icon} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mi-resume-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Resume" />
          <Smalltitle title="Working Experience" icon="briefcase" />
          <div className="mi-resume-wrapper">
            {workingExperience.map((workingExp) => (
              <Resume key={workingExp.id} resumeData={workingExp} />
            ))}
          </div>
          <div className="mt-30"></div>
          <Smalltitle title="Educational Qualifications" icon="book" />
          <div className="mi-resume-wrapper">
            {educationExperience.map((educatonExp) => (
              <Resume key={educatonExp.id} resumeData={educatonExp} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Resumes;
